<ng-template [ngIf]="control">
    <div *ngIf="isSelectType && control">
        <app-icon *ngIf="matInitIcon" class="btn-icon" class="pr-2 pt-2" [iconClass]="matInitIcon">
        </app-icon>
        <mat-form-field [appearance]="appearance">
            <mat-label>{{ label }}</mat-label>
            <mat-select [formControl]="control" [required]="isRequired" #select
                (selectionChange)="emitSelectionChange($event.value)" [multiple]="isMultiple">
                <div class="select-all" *ngIf="isMultiple && showFilter">
                    <div class="">
                        <mat-label>Filtrar por:</mat-label>
                        <input type="text" matInput [(ngModel)]="textInput" (keydown)="$event.stopPropagation()"
                            (keyup)="emitOnKeyup($event.target.value)" [ngModelOptions]="{ standalone: true }"
                            #filterInput id="filterInput" [placeholder]="filterPlaceholder" class="py-3">
                    </div>
                </div>
                <div class="selectAll" *ngIf="showSelectAll">
                    <mat-checkbox [(ngModel)]="allSelected" [ngModelOptions]="{standalone: true}"
                        (change)="toggleSelection()" color="primary">
                        Selecionar todos da lista
                    </mat-checkbox>
                </div>
                <hr class="m-0" />
                <mat-option [value]="" *ngIf="!selectData?.length" disabled>
                    Nenhum resultado encontrado
                </mat-option>
                <mat-option *ngIf="showOptionAll" [value]="optionAll"> {{ optionAllText }} </mat-option>
                <mat-option *ngFor="let item of selectData" [value]="item[selectValue]" [disabled]="item.disabled "
                    (click)="handleOptionOnClick($event)" (selectionChange)="setAllSelectFalse()">
                    {{ item[text] }}
                </mat-option>
            </mat-select>
            <mat-error *ngIf="control?.status === 'INVALID'">{{ errorMessage }}</mat-error>
        </mat-form-field>
    </div>

    <div *ngIf="!isSelectType && control">
        <div [formGroup]="formBase" class="d-flex">
            <app-icon *ngIf="matInitIcon" class="btn-icon" class="pr-1 pt-4" [iconClass]="matInitIcon">
            </app-icon>
            <mat-form-field [appearance]="appearance">
                <mat-label>{{ label }}</mat-label>
                <div class="d-flex">
                    <span matTextPrefix="matTextPrefix" *ngIf="prefix && control?.status === 'VALID'"
                        class="pr-2 text-dark"> {{ prefix }} </span>
                    <input matInput="matInput" [placeholder]="placeholder" [formControl]="control"
                        [required]="isRequired" [mask]="mask" [patterns]="patterns"
                        [dropSpecialCharacters]="dropSpecialCharacters" [readonly]="isReadOnly || disable"
                        (change)="emitInputChange($event)" (input)="emitOnInput($event)" [type]="inputType"
                        (keyup)="emitOnKeyup($event.target.value)" (keypress)="emitOnKeypress($event)"
                        [ngClass]="inputCss">
                    <mat-icon *ngIf="matSuffixIcon && !showAppIconMatSuffix" [ngClass]="iconSuffixClass" matSuffix
                        (click)="emitMatSuffixIconClick()">
                        {{ matSuffixIcon }}
                    </mat-icon>
                    <app-icon *ngIf="matSuffixIcon && showAppIconMatSuffix" class="btn-icon" [ngClass]="iconSuffixClass"
                        [iconClass]="matSuffixIcon" (click)="emitMatSuffixIconClick()">
                    </app-icon>
                    <span matTextSuffix="matTextSuffix" *ngIf="suffix && control?.status === 'VALID'"
                        class="pl-2 text-dark"> {{ suffix }} </span>
                </div>
                <mat-error *ngIf="control?.status === 'INVALID'">{{ errorMessage }}</mat-error>
                <mat-hint>{{ hint }}</mat-hint>
            </mat-form-field>
        </div>
    </div>
</ng-template>

<ng-template [ngIf]="!control">
    <div *ngIf="isSelectType" class="d-flex align-items-center">
        <app-icon *ngIf="matInitIcon" class="btn-icon" class="pr-2 pt-2" [iconClass]="matInitIcon">
        </app-icon>
        <mat-form-field [appearance]="appearance">
            <mat-label>{{ label }}</mat-label>
            <mat-select [(ngModel)]="modelNg" [required]="isRequired" [disabled]="disable"
                (selectionChange)="emitSelectionChange($event.value)" [multiple]="isMultiple">
                <div class="selectAll" *ngIf="showSelectAll">
                    <mat-checkbox [(ngModel)]="allSelected" [ngModelOptions]="{standalone: true}"
                        (change)="toggleSelection()" color="primary">
                        Selecionar todos da lista
                    </mat-checkbox>
                </div>
                <hr class="m-0" />
                <mat-option [value]="" *ngIf="!selectData?.length" disabled>
                    Nenhum resultado encontrado
                </mat-option>
                <mat-option *ngIf="showOptionAll" [value]="optionAll"> Todos </mat-option>
                <mat-option *ngFor="let item of selectData" (selectionChange)="setAllSelectFalse()"
                    [value]="item[selectValue]" [disabled]="item.disabled">
                    {{ item[text] }}
                </mat-option>
            </mat-select>
            <mat-error *ngIf="modelNgInvalid">{{ errorMessage }}</mat-error>
        </mat-form-field>
    </div>

    <div *ngIf="!isSelectType" [ngClass]="inputClass" class="d-flex">
        <app-icon *ngIf="matInitIcon" class="btn-icon" class="pr-1 pt-4" [iconClass]="matInitIcon">
        </app-icon>
        <mat-form-field [appearance]="appearance">
            <mat-label>{{ label }}</mat-label>
            <div class="d-flex">
                <span matTextPrefix="matTextPrefix" *ngIf="prefix && !modelNgInvalid" class="pr-2 text-dark">
                    {{ prefix }} </span>
                <input matInput="matInput" [placeholder]="placeholder" [(ngModel)]="modelNg" [required]="isRequired"
                    [mask]="mask" [patterns]="patterns" [dropSpecialCharacters]="dropSpecialCharacters"
                    [type]="inputType" (input)="emitValue($event.target.value)" [disabled]="disable"
                    (keyup)="emitOnKeyup($event.target.value)" [readonly]="isReadOnly"
                    (keypress)="emitOnKeypress($event)" (change)="emitInputChange($event)">
                <mat-icon *ngIf="matSuffixIcon && !showAppIconMatSuffix" [ngClass]="iconSuffixClass" matSuffix
                    (onClick)="emitMatSuffixIconClick()">
                    {{ matSuffixIcon }}
                </mat-icon>
                <app-icon *ngIf="matSuffixIcon && showAppIconMatSuffix" class="btn-icon" [ngClass]="iconSuffixClass"
                    [iconClass]="matSuffixIcon" (click)="emitMatSuffixIconClick()">
                </app-icon>
                <span matTextSuffix="matTextSuffix" *ngIf="suffix && !modelNgInvalid" class="pl-2 text-dark">
                    {{ suffix }} </span>
            </div>
            <mat-error *ngIf="modelNgInvalid">{{ errorMessage }}</mat-error>
            <mat-hint>{{ hint }}</mat-hint>
        </mat-form-field>
    </div>
</ng-template>