import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { NgxSpinnerService } from 'ngx-spinner';
import { environment } from 'src/environments/environment';
import { UtilsService } from './utils.service';
import { catchError, take } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class ForgotPasswordService {
  constructor(
    private http: HttpClient,
    private spinner: NgxSpinnerService,
    private utils: UtilsService,
    private router: Router,
  ) { }

  obfuscateEmail(email: string): string {
    const [localPart, domain] = email.split("@");
    if (!localPart || !domain) {
      throw new Error("Email inválido");
    }
    const firstChar = localPart.charAt(0);
    const lastChar = localPart.charAt(localPart.length - 1);
    const hiddenPart = "*".repeat(localPart.length - 2);

    return `${firstChar}${hiddenPart}${lastChar}@${domain}`;
  }

  logout() {
    this.utils.clearLocalStorage();
    this.router.navigate(['auth/signin']);
  }




  forgotPasswordByDocument(document: string) {
    this.spinner.show();
    const payload = { document };
    const cpf = document.replace(/[.,-]/g, "")

    return this.http
      .get(`${environment.urlApi}/Account/PasswordSendEmail/${cpf}`)
      .pipe(
        take(1),
        catchError(err => {
          this.spinner.hide();
          this.utils.showToastDialog(
            'Os dados informados não correspondem a nenhum usuário cadastrado em nosso sistema. Por favor, revise as informações e tente novamente',
            'warning'
          );
          return throwError(err);
        })
      )
      .subscribe(
        () => {
          this.spinner.hide();
          this.utils.showToastDialog(
            'Sua nova senha foi enviada para o e-mail cadastrado. Caso não encontre o e-mail, verifique a pasta de spam',
            'success'
          );
        },
        err => {
          console.error(err);
        }
      );
  }
  forgotPasswordById(id: string) {
    this.spinner.show();
    const payload = { id };

    return this.http
      .put(`${environment.urlApi}/Account/ResetCode/${id}`, payload)
      .pipe(
        take(1),
        catchError(err => {
          this.spinner.hide();
          this.utils.showToastDialog(
            'Erro ao redefinir senha',
            'warning'
          );
          return throwError(err);
        })
      )
      .subscribe(
        () => {
          this.spinner.hide();
          this.utils.showSuccessDialog(
            'Seu novo código foi gerado.',
            true,
            'Confirmar',
            true
          )
        },
        err => {
          console.error(err);
        }
      );
  }


}
