import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { take } from 'rxjs/operators';
import { UtilsService } from './utils.service';
import { environment } from 'src/environments/environment';
import { IAuth, userInfo } from './../models/auth.model';
import { PermissionsService } from './permissions.service';
import { ThemeService } from './theme.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  user: string;
  userInfo: userInfo;

  constructor(
    private router: Router,
    protected http: HttpClient,
    private _utils: UtilsService,
    private spinner: NgxSpinnerService,
    private themeService: ThemeService,
    private permissionsService: PermissionsService,
  ) { }

  fazerLogin(usuario: IAuth) {
    this.spinner.show();
    this.http
      .post(`${environment.urlApi}/Middleware/Login`, usuario)
      .pipe(take(1))
      .subscribe(
        res => {
          this.spinner.hide();
          const data = res["content"]["data"];
          this._utils.setTokenLocalStorage(data.token);
          this._utils.setTenantIdLocalStorage(data.entities?.[0]?.id || "");
          this._utils.setEntitiesLocalStorage(data.entities);
          this.getProfile();
          this.themeService.setLayoutConfig();
        },
        err => {
          console.error(err);
          this.spinner.hide();
          this._utils.clearLocalStorage();
          this._utils.showToastDialog("Nome de usuário ou senha incorreta.", "warning");
        }
      );
  }

  logout() {
    this._utils.clearLocalStorage();
    this.router.navigate(['auth/signin']);
  }

  isLogged(): string {
    return this._utils.tokenLocalStorage;
  }

  getProfile(goToManagement = true) {
    this.spinner.show();
    this.http
      .get(`${environment.urlApi}/Account/user-profile/v2`)
      .pipe(take(1))
      .subscribe(
        res => {
          const data = res["content"]["data"];
          const roles = data?.profile || [];

          this._utils.setUserIdLocalStorage(data.id);
          this._utils.setUserLocalStorage(data.fullName);

          const permissionList = this.permissionsService.permissionsList;
          let list: string[] = [];

          data.permissions?.forEach(permission => {
            permissionList?.forEach(element => {
              if (permission === element.description) {
                list.push(element.permission)
              }
            })
          });

          const userInfo = {
            id: data.id,
            name: data.fullName,
            permissions: list,
            token: this._utils.tokenLocalStorage,
            entities: this._utils.entitiesLocalStorage
          }

          this._utils.setUserInfoLocalStorage(userInfo);
          this._utils.setRolesLocalStorage(roles);

          roles?.forEach(role => {
            if (role == 'Informações Fiscais' || role == 'Informações - Fiscais') {
              this._utils.setInfoFiscaisLocalStorage();
            }
            if (role == 'Informações Sistêmicas' || role == 'Informações - Sistêmicas') {
              this._utils.setInfoSistemicasLocalStorage();
            }
            if (role == 'Informações Operacionais' || role == 'Informações - Operacionais') {
              this._utils.setInfoOperacionaisLocalStorage();
            }
          });
          if (goToManagement) {
            this.router.navigate(['dashboard/management']);
          }
          this.spinner.hide();
        },
        err => {
          this.spinner.hide();
          console.error(err);
          this._utils.showToastDialog("Alguma coisa deu errado", "warning");
        }
      );
  }
  tokenAuthentication(token: string) {
    this._utils.setTokenLocalStorage(token);
    this.getProfile(false);
    this.themeService.setLayoutConfig();
  }
  setToken(token: string) {
    this._utils.setTokenLocalStorage(token);
  }

}
