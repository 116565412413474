import {
  Component, Input, Output, EventEmitter,
  ViewChild, AfterViewInit
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatSelect } from '@angular/material/select';

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss']
})

export class InputComponent implements AfterViewInit {
  textInput = "";
  currentSelect = "";
  @ViewChild('select') select: MatSelect;
  @Input() mask = "";
  @Input() patterns;
  @Input() text = "";
  @Input() hint = "";
  @Input() label = "";
  @Input() prefix = "";
  @Input() suffix = "";
  @Input() inputClass = "";
  @Input() matInitIcon = "";
  @Input() selectValue = "";
  @Input() placeholder = "";
  @Input() matSuffixIcon = "";
  @Input() inputType = "text";
  @Input() iconSuffixClass = "";
  @Input() appearance = "outline";
  @Input() errorMessage = "*Campo obrigatório";
  @Input() filterPlaceholder = "Informe o item";

  @Input() formBase: FormGroup;
  @Input() control: FormControl;

  @Input() invalid = false;
  @Input() disable = false;
  @Input() isMultiple = false;
  @Input() isReadOnly = false;

  @Input() optionAll = "";
  @Input() showOptionAll = false;
  @Input() optionAllText = "Todos";

  @Input() showSelectAll = false;
  @Input() allSelected = false;
  @Output() allSelectedChange = new EventEmitter();

  @Input() isRequired = false;
  @Input() isSelectType = false;
  @Input() showFilter = false;
  @Input() showAppIconMatSuffix = false;
  @Input() dropSpecialCharacters = true;

  @Input() selectData = [];
  @Input() modelNg = null;
  @Input() modelNgInvalid = false;
  @Output() modelNgChange = new EventEmitter();
  @Output() onChange = new EventEmitter();
  @Output() onToggleSelection = new EventEmitter();
  @Output() onSelectionChange = new EventEmitter();
  @Output() onSelectMultipleItem = new EventEmitter();
  @Output() onMatSuffixIconClick = new EventEmitter();

  @Output() onInput = new EventEmitter();
  @Output() onInputChange = new EventEmitter();

  @Output() onKeyup = new EventEmitter();
  @Output() onKeypress = new EventEmitter();

  constructor() { }

  ngAfterViewInit() {
    this.select?.optionSelectionChanges?.subscribe(change => {
      this.currentSelect = change.source.value;
    });
  }

  emitValue<T>(value: T) {
    this.modelNgChange.emit(value);
    this.onChange.emit();
  }

  emitSelectionChange(value: string[]) {
    if (this.isMultiple && this.showOptionAll) {
      const hasOptionAllSelected = this.modelNg?.some(str => str === "");
      if (hasOptionAllSelected) {
        this.modelNg = [""];
        value = [""];
      }
    }

    this.modelNgChange.emit(value);
    if (this.onSelectionChange) {
      this.modelNgChange.emit(value);
      this.onSelectionChange.emit(value);
    }
  }

  emitInputChange<T>(value?: T) {
    if (this.onInputChange) {
      this.onInputChange.emit(value);
    }
  }

  emitOnInput<T>(value: T) {
    if (this.onInput) {
      this.onInput.emit(value);
    }
  }

  emitOnKeyup(value?) {
    if (this.onKeyup) {
      this.onKeyup.emit(value);
    }
  }

  emitOnKeypress(event: KeyboardEvent) {
    if (this.onKeypress) {
      this.onKeypress.emit(event);
    }
  }

  handleOptionOnClick(event) {
    event?.target?.scrollIntoView();
    if (this.onSelectMultipleItem) {
      this.onSelectMultipleItem?.emit(this.currentSelect);
    }
  }

  emitMatSuffixIconClick() {
    if (this.onMatSuffixIconClick) {
      this.onMatSuffixIconClick?.emit();
    }
  }

  toggleSelection() {
    if (this.allSelected) {
      this.modelNg = this.selectData.map(el => el[this.selectValue]);
    } else {
      this.modelNg = [];
    }

    this.allSelectedChange.emit(this.allSelected);
    this.emitSelectionChange(this.modelNg);
  }

  setAllSelectFalse() {
    this.allSelected = false;
    this.allSelectedChange.emit(this.allSelected);
    this.emitSelectionChange(this.modelNg);
  }

  get inputCss(): string {
    return this.inputType === "time" ? "inputTime" : "";
  }
}
